<template>
  <div class="contents">
    <ResponseModal ref="resModal" style="z-index: 9999999 !important" />
    <div class="col-lg-12">
      <div class="breadcrumb-main add-contact justify-content-sm-between">
        <div
          class="
            d-flex
            flex-wrap
            justify-content-center
            breadcrumb-main__wrapper
          "
        >
          <div
            class="
              d-flex
              align-items-center
              add-contact__title
              justify-content-center
            "
          >
            <h4 class="text-capitalize fw-500 breadcrumb-title">
              <span  class="link" @click="navigateBack"
                ><a><i class="las la-arrow-left"></i></a></span>
              {{ questionMeta.tname }}
              <ul class="atbd-breadcrumb nav">
                <li class="atbd-breadcrumb__item">
                  <a @click="navigateBack" class="link">
                    <span class="la la-home"></span>
                  </a>
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>

                <li class="atbd-breadcrumb__item" @click="navigateBack">
                  <a class="link">Question</a>
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>

                <li class="atbd-breadcrumb__item">
                  <span>Edit</span>
                </li>
              </ul>
            </h4>

            <span class="sub-title ml-sm-25 pl-sm-25"></span>
          </div>
        </div>
        <div class="d-flex">
          <div class="alert alert-warning mr-3" v-if="retryQueue.length">
            <p>
              <strong
                >Oops! Connnection snapped.
                <span class="link" @click="retryAction">Retry</span> to load
                page correctly
                <span
                  class="fa fa-refresh ml-3 mr-0 link"
                  :class="{ spin: retrying }"
                  @click="retryAction"
                ></span
              ></strong>
            </p>
          </div>
          <div class="action-btn">
            <a
              class="link btn px-15 btn-primary text-white"
              @click="buildQuestion"
              v-if="showBuild && !correctAnswerEditMode && !mediaError"
            >
              <i class="las la-plus fs-16"></i>Build</a
            >
            <!-- Modal -->

            <!-- Modal -->
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <!-- Tab header -->
      <div class="ap-tab ap-tab-header">
        <div class="ap-tab-wrapper">
          <ul class="nav px-25 ap-tab-main" id="ap-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="question-tab"
                data-toggle="pill"
                href="#question"
                role="tab"
                aria-controls="question"
                aria-selected="true"
                >Questions</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="answer-tab"
                data-toggle="pill"
                href="#answer"
                role="tab"
                aria-controls="answer"
                aria-selected="false"
                >Answers / tips</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="content-restore-tab"
                data-toggle="pill"
                href="#content-restore"
                role="tab"
                aria-controls="content-restore"
                aria-selected="false"
                >Content Restore</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- Tab Header end -->
      <!-- Tab Body Start -->
      <loader-overlay
        :active="true"
        v-if="!asyncLoader && loadingQuestion"
        :zIndex="999999"
        position="absolute"
        :height="80"
      />
      <div class="tab-content mt-25" id="ap-tabContent" v-if="question">
        <div
          class="tab-pane fade active show"
          id="question"
          role="tabpanel"
          aria-labelledby="question-tab"
        >
          <div class="ap-content-wrapper">
            <div class="row">
              <div class="col-md-3 d-none d-md-block">
                <QuestionAside :question="question" :topicName="`${questionMeta.tname}`" />
              </div>
              <div class="col-md-9">
                <Editor
                
                  :previewPane="{
                    header: true,
                    editButton: true,
                    trashButton: true,
                    name: 'questionBuilder',
                    clid: questionMeta.qlid,
                    contentType: 'question',
                  }"
                  :editorPane="{ header: true, wrap: true }"
                  :config="{
                    name: 'qBuilder',
                    type: 1,
                    data: whiteQuestionContent,
                    contentId: question.qlid,
                  }"
                  @contentOperations="contentOperations"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="answer"
          role="tabpanel"
          aria-labelledby="answer-tab"
        >
          <div class="ap-content-wrapper">
            <div class="row">
              <div class="col-md-8 mx-auto">
                <div class="note-contents">
                  <!-- ends: .col-lg-2 -->
                  <div class="note-grid-wrapper mb-30">
                    <div class="notes-wrapper">
                      <div class="card-body">
                        <div class="atbd-nav-controller">
                          <div
                            class="
                              btn-group
                              atbd-button-group
                              btn-group-normal
                              nav
                              padtab2
                            "
                            role="tablist"
                          >
                            <a
                              href="#answer-options"
                              class="
                                btn btn-sm btn-outline-light
                                nav-link
                                active
                              "
                              data-toggle="tab"
                              role="tab"
                              aria-controls="answer-options"
                              aria-selected="true"
                              >Options</a
                            >
                            <a
                              href="#answer-solution"
                              class="btn btn-sm btn-outline-light nav-link"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="answer-solution"
                              aria-selected="false"
                              >Solution</a
                            >
                            <a
                              href="#answer-tip"
                              class="btn btn-sm btn-outline-light nav-link"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="answer-tips"
                              aria-selected="false"
                              >Tip</a
                            >
                          </div>
                        </div>
                        <div class="tab-content">
                          <!-- Add Option Modal -->
                          <div
                            class="modal fade"
                            id="addOption"
                            v-if="question.type == 16"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modelTitleId"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-lg" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Add Option</h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div
                                    :class="{ error: $v.openAnswer.$error }"
                                    class="mb-3"
                                  >
                                    <vue-simplemde
                                      v-model.trim="$v.openAnswer.$model"
                                      ref="open-q-smde"
                                      :autoinit="true"
                                    />
                                  </div>

                                  <span @click="addOpenAnswer">
                                    <SpinnerButton
                                      label="Add"
                                      :loading="loading.openAnswer"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="modal fade"
                            v-else
                            id="addOption"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modelTitleId"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-lg" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Add Option</h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <Editor
                                    :previewPane="false"
                                    :editorPane="{ header: false, wrap: false }"
                                    :config="{
                                      name: 'optBuilder',
                                      type: 2,
                                      data: answerPlaceHolder,
                                      contentId: question.qlid,
                                    }"
                                    @answerOperations="answerOperations"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane active pb-2" id="answer-options">
                            <div
                              class="alert alert-warning px-2"
                              role="alert"
                              style="margin: -8px -10px 13px -10px"
                              v-if="!addOptionStatus"
                            >
                              <p class="mb-0">Maximum Options reached.</p>
                            </div>
                            <div class="row">
                              <div
                                v-for="(a, k) in whiteAnswerContent"
                                :key="k"
                                class="col-6 px-1"
                                :class="{ 'col-12': question.type == 16 }"
                              >
                                <AnswerCard
                                  :answer="a"
                                  :meta="{
                                    index: k,
                                    label: question.label,
                                    correct:
                                      currentCorrectAnswer.indexOf(a.alid) >=
                                        0 || question.type == 16,
                                    rank: '',
                                  }"
                                  :type="question.type"
                                  :ref="a.alid"
                                  @answerOperations="answerOperations"
                                />
                              </div>
                            </div>
                            <div
                              class="addbtn d-flex justify-content-center mt-3"
                              v-if="!correctAnswerEditMode"
                            >
                              <button
                                data-toggle="modal"
                                data-target="#addOption"
                                class="
                                  btn btn-icon btn-primary btn-circle
                                  mx-auto
                                  mt-20
                                  cusbtn
                                "
                                :disabled="!addOptionStatus"
                              >
                                <span class="las la-plus"></span>
                              </button>
                            </div>
                            <div class="button-group d-flex pt-25" v-else>
                              <button
                                class="
                                  btn btn-icon btn-success btn-circle
                                  mx-auto
                                  mt-20
                                  cusbtn
                                "
                                @click="submitCorrectAnswer"
                                :disabled="disableCommitAnswer"
                              >
                                <span class="las la-check"></span>
                              </button>
                              <button
                                class="
                                  btn btn-icon btn-danger btn-circle
                                  mx-auto
                                  mt-20
                                  cusbtn
                                "
                                @click="resetAnswer"
                              >
                                <span class="las la-times"></span>
                              </button>
                            </div>

                            <div class="no-content text-center" v-if="false">
                              <img
                                src="/assets/img/custom/fish.gif"
                                width="200"
                              />
                              <br />
                              <h3>Oops</h3>
                              <h6>No answer</h6>
                              <br />
                              <button
                                id="addOptionRoundButton"
                                class="
                                  btn
                                  btn-primary
                                  btn-default
                                  btn-squared
                                  btn-shadow-primary
                                  mx-auto
                                "
                                data-toggle="modal"
                                data-target="#addOption"
                              >
                                Add Answer
                              </button>
                            </div>
                          </div>
                          <div class="tab-pane" id="answer-solution">
                            <Editor
                              :previewPane="{
                                header: false,
                                editButton: true,
                                trashButton: true,
                                name: 'solutionBuilder',
                                clid: solutionContentId,
                                contentTypee: 'solution',
                              }"
                              :editorPane="{}"
                              :config="{
                                name: 'solution',
                                type: 8,
                                data: whiteSolutionContent,
                                contentId: question.solution,
                              }"
                              @contentOperations="contentOperations"
                            />
                          </div>
                          <div class="tab-pane" id="answer-tip">
                            <Editor
                              :previewPane="{
                                header: false,
                                editButton: true,
                                trashButton: true,
                                name: 'tipBuilder',
                                clid: tipContentId,
                                contentType: 'tip',
                              }"
                              :editorPane="{}"
                              :config="{
                                name: 'tip',
                                type: 4,
                                data: whiteTipContent,
                                contentId: question.tip,
                              }"
                              @contentOperations="contentOperations"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade mb-4"
          id="content-restore"
          role="tabpanel"
          aria-labelledby="content-restore-tab"
        >
          <div class="ap-content-wrapper">
            <div class="row">
              <div class="col-md-6 mx-auto">
                <div class="card">
                  <div class="card-body">
                    <div class="atbd-nav-controller">
                      <div
                        class="
                          btn-group
                          atbd-button-group
                          btn-group-normal
                          nav
                          padtab2
                        "
                        role="tablist"
                      >
                        <a
                          href="#content-restore-question"
                          class="btn btn-sm btn-outline-light nav-link active"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="content-restore-question"
                          aria-selected="true"
                          >Question</a
                        >
                        <a
                          href="#content-restore-answer"
                          class="btn btn-sm btn-outline-light nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="content-restore-answer"
                          aria-selected="false"
                          >Answer</a
                        >
                        <a
                          href="#content-restore-solution"
                          class="btn btn-sm btn-outline-light nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="content-restore-solution"
                          aria-selected="false"
                          >Solution</a
                        >
                        <a
                          href="#content-restore-tip"
                          class="btn btn-sm btn-outline-light nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="content-restore-tip"
                          aria-selected="false"
                          >Tip</a
                        >
                      </div>
                    </div>
                    <div class="tab-content">
                      <div
                        class="tab-pane active"
                        id="content-restore-question"
                      >
                        <PreviewPane
                          :previewPane="{
                            header: false,
                            restoreButton: true,
                            name: 'restoreQuestion',
                            contentType: 'question',
                            clid: questionMeta.qlid,
                          }"
                          :dataPool="blackQuestionContent"
                          @contentOperations="contentOperations"
                        />
                      </div>
                      <div class="tab-pane" id="content-restore-answer">
                        <PreviewPane
                          :previewPane="{
                            header: false,
                            restoreButton: addOptionStatus,
                            name: 'restoreAnswer',
                            contentType: 'answer',
                          }"
                          :dataPool="blackAnswerContent"
                          @answerOperations="answerOperations"
                        />
                      </div>
                      <div class="tab-pane" id="content-restore-solution">
                        <PreviewPane
                          :previewPane="{
                            header: false,
                            restoreButton: true,
                            name: 'restoreSolution',
                            contentType: 'solution',
                            clid: solutionContentId,
                          }"
                          :dataPool="blackSolutionContent"
                          @contentOperations="contentOperations"
                        />
                      </div>
                      <div class="tab-pane" id="content-restore-tip">
                        <PreviewPane
                          :previewPane="{
                            header: false,
                            restoreButton: true,
                            name: 'restoreTip',
                            contentType: 'tip',
                            clid: tipContentId,
                          }"
                          :dataPool="blackTipContent"
                          @contentOperations="contentOperations"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuestionAside from "@/components/qbuilder/QuestionAside.vue";
import Editor from "@/components/qbuilder/Editor.vue";
import AnswerCard from "@/components/qbuilder/AnswerCard.vue";
import PreviewPane from "@/components/qbuilder/PreviewPane.vue";
import ResponseModal from "@/components/ResponseModal.vue";
import EventBus from "@/plugins/eventbus";
import { questionBuildContraint } from "@/utilities/constant";
import jQ from "jquery";
import SpinnerButton from "@/components/SpinnerButton.vue";
import { apiClient } from "@/plugins/restclient";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    QuestionAside,
    Editor,
    AnswerCard,
    PreviewPane,
    ResponseModal,
    LoaderOverlay,
    SpinnerButton,
  },
   metaInfo: {
    title: 'Greater Favour',
    titleTemplate: '%s | Question Add',
  },
  created() {
    // const questionMeta = this.$route.params;

    // if (Object.keys(questionMeta).length > 0) {
    //   localStorage.setItem("questionMeta", JSON.stringify(questionMeta));
    // }
    this.questionMeta = JSON.parse(localStorage.getItem("questionMeta"));

    this.questionPull("created");
    this.contentLoad(this.questionMeta.qlid, "question");
    EventBus.$on("answerSelect", this.selectAnswer);
    EventBus.$on("contentOperations", this.contentOperations);
  },
  computed: {
    ...mapState(["asyncLoader"]),
    correctAnswerEditMode() {
      let status = null;

      if (
        this.currentCorrectAnswer.join("") == this.newCorrectAnswer.join("")
      ) {
        status = false;
      }
      if (
        this.currentCorrectAnswer.join("") != this.newCorrectAnswer.join("")
      ) {
        status = true;
      }
      // if(this.question.type == 16 ){
      //     status = false
      // }

      //   if(this.currentCorrectAnswer.length == this.newCorrectAnswer.length){
      //     if(this.currentCorrectAnswer.length == 0){
      //         return true
      //     }
      //     for(let i of this.currentCorrectAnswer){
      //         status = this.newCorrectAnswer.indexOf(i) >= 0
      //         if(status == false){
      //             return false
      //         }
      //     }
      //   }else{
      //       status = false
      //   }

      return status;
    },
  },
  data() {
    return {
      question: "",
      questionContent: "",
      whiteQuestionContent: [],
      blackQuestionContent: [],
      whiteAnswerContent: [],
      blackAnswerContent: [],
      whiteTipContent: [],
      blackTipContent: [],
      whiteSolutionContent: [],
      blackSolutionContent: [],
      answers: [],
      answerPlaceHolder: "",
      solution: "",
      content: "",
      tip: "",
      currentCorrectAnswer: [],
      newCorrectAnswer: [],
      questionMeta: "",
      questionBuildContraint: "",
      showBuild: false,
      addOptionStatus: true,
      tipContentId: "",
      solutionContentId: "",
      questionContentId: "",
      retryQueue: [],
      retrying: false,
      mediaError: false,
      disableCommitAnswer: false,
      loadingQuestion: false,
      loading: {
        openAnswer: false,
      },
      openAnswer: "",
      pageContext: "created",
    };
  },
  watch: {
    newCorrectAnswer(correct) {
      if (this.question.type == 8) {
        if (correct.length != this.whiteAnswerContent.length) {
          this.disableCommitAnswer = true;
        } else {
          this.disableCommitAnswer = false;
        }
      } else if (this.question.type == 4) {
        if (
          correct.length > 1 &&
          correct.length <= this.whiteAnswerContent.length
        ) {
          this.disableCommitAnswer = false;
        } else {
          this.disableCommitAnswer = true;
        }
      }
      this.questionBuild();
    },
    whiteAnswerContent() {
      this.questionBuild();
    },
    whiteSolutionContent() {
      this.questionBuild();
    },
    whiteTipContent() {
      this.questionBuild();
    },
    whiteQuestionContent() {
      this.questionBuild();
    },
  },
  methods: {
    navigateBack(){
      window.history.length === 1 ? this.$router.replace('/') : this.$router.go(-1)
    },
    addOpenAnswer() {
      this.$v.openAnswer.$touch();
      if (this.$v.openAnswer.$error) {
        return;
      }
      this.loading.openAnswer = true;
      apiClient
        .patch("/v1/office/assessment/question/correct", {
          qlid: this.question.qlid,
          items: [this.openAnswer],
        })
        .then(() => {
          this.loading.openAnswer = false;
          Window.closeVueBsModal("addOption");
          this.currentCorrectAnswer = [this.openAnswer];
          this.newCorrectAnswer = this.currentCorrectAnswer;
          this.whiteAnswerContent = [
            {
              type: 16,
              data: this.currentCorrectAnswer.toString(),
              status: 1,
              engine: "text",
            },
          ];
          // this.questionPull()
        })
        .catch((err) => {
          this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 8000,
          });
          this.loading.openAnswer = false;
        });
    },
    answerOperations(data) {
      if (data.action == "delete") {
        this.whiteAnswerContent.filter((el, i) => {
          if (el.alid == data.answer.alid) {
            this.whiteAnswerContent.splice(i, 1);
          }
        });

        this.resetAnswer();
        let index = "";
        index = this.newCorrectAnswer.indexOf(data.answer.alid);

        if (index != -1) {
          this.newCorrectAnswer.splice(index, 1);
          this.submitCorrectAnswer();
        }
        this.fetchAnswers();
      } else if (data.action == "add") {
        if (data.engine != "media" || data.mediaAdd == true) {
          Window.closeVueBsModal("addOption");
        }

        this.fetchAnswers();
        setTimeout(() => {
          this.resetAnswer();
        }, 600);
      } else if (data.action == "edit") {
        if (data.answer.engine == "media") {
          Window.openVueBsModal("addOption");
        } else if (data.answer.engine == "text") {
          this.openAnswer = data.answer.data;
          this.$refs["open-q-smde"].simplemde.codemirror.setValue(
            data.answer.data
          );
          Window.openVueBsModal("addOption");
        }
      }
    },
    questionPull(ctx) {
      this.loadingQuestion = true;
      apiClient
        .get("/v1/office/assessment/question/pull/" + this.questionMeta.qlid)
        .then((res) => {
          this.loadingQuestion = false;
          const data = res.data.info;
          this.question = data.question;

          this.questionBuildContraint =
            questionBuildContraint[this.question.type];

          const { solution, tip, answer } = this.question;
          this.solutionContentId = solution;
          this.tipContentId = tip;

          this.currentCorrectAnswer = answer ? answer.split("|") : [];

          if (ctx == "created") {
            this.newCorrectAnswer = [...this.currentCorrectAnswer];
            this.fetchAnswers();
            this.contentLoad(solution, "solution");
            this.contentLoad(tip, "tip");
          }
        })
        .catch(() => {
          this.loadingQuestion = false;
          this.retryQueue.push({ action: "questionPull", params: { ctx } });
        });
    },
    questionBuild() {
      const correctAnswer = this.newCorrectAnswer.length;
      const options = this.whiteAnswerContent.length;
      const solution = this.whiteSolutionContent.length;
      const question = this.whiteQuestionContent.length;

      switch (this.question.type) {
        case 1:
          if (options == 2) {
            this.addOptionStatus = false;
          } else {
            this.addOptionStatus = true;
          }
          if (
            question > 0 &&
            options == 2 &&
            solution > 0 &&
            correctAnswer == 1
          ) {
            this.showBuild = true;
          } else {
            this.showBuild = false;
          }

          break;
        case 2:
          if (options == 5) {
            this.addOptionStatus = false;
          } else {
            this.addOptionStatus = true;
          }
          if (
            question > 0 &&
            options > 3 &&
            solution > 0 &&
            correctAnswer == 1
          ) {
            this.showBuild = true;
          } else {
            this.showBuild = false;
          }
          break;
        case 4:
          if (options == 5) {
            this.addOptionStatus = false;
          } else {
            this.addOptionStatus = true;
          }
          if (
            question > 0 &&
            options > 3 &&
            solution > 0 &&
            correctAnswer > 1
          ) {
            this.showBuild = true;
          } else {
            this.showBuild = false;
          }
          break;
        case 8:
          if (
            question > 0 &&
            options == correctAnswer &&
            solution > 0 &&
            options > 3
          ) {
            this.showBuild = true;
          } else {
            this.showBuild = false;
          }
          break;
        case 16:
          if (options == 1) {
            this.addOptionStatus = false;
          } else {
            this.addOptionStatus = true;
          }
          if (
            question > 0 &&
            options == 1 &&
            solution > 0 &&
            correctAnswer == 1
          ) {
            this.showBuild = true;
          } else {
            this.showBuild = false;
          }
          break;
        default:
          break;
      }
      //   let { correctAnswer, options, solution} = this.questionBuildContraint
      //     let optC = ''
      //     let ansC = ''
      //     let solC = ''

      //     correctAnswer = correctAnswer.split(',')
      //     if(correctAnswer.length == 2){
      //         ansC =  eval(`${this.newCorrectAnswer.length}${correctAnswer[0]}`)
      //         ansC =  ansC && eval(`${this.newCorrectAnswer.length}${correctAnswer[1]}`)

      //     }else{
      //         ansC = eval(`${this.newCorrectAnswer.length}${correctAnswer[0]}`)

      //     }

      //     options = options.split(',')
      //     if(options.length == 2){
      //         optC =  eval(this.whiteAnswerContent.length + options[0])
      //         optC =  optC && eval(this.whiteAnswerContent.length + options[1])

      //     }else{
      //         optC = eval(this.whiteAnswerContent.length + options[0])
      //     }

      //    solC = solution == (this.whiteSolutionContent.length>0) ? true : false

      //   if(optC && ansC && solC){
      //       this.showBuild = true
      //   }else{
      //       this.showBuild = false
      //   }
    },
    contentLoad(typeId, type, loaderSelector, buttonSelector) {
      apiClient
        .get("/v1/office/assessment/content/load/" + typeId)
        .then((res) => {
          jQ(loaderSelector).toggleClass("no-display");
          jQ(buttonSelector).prop("disabled", false);
          this.content = res.data.data;
          this.content = this.content.map((d) => {
            const dataItem = {
              ...d.content,
              assets: d.assets,
              validAssetLength: JSON.stringify(d.assets).length,
            };
            if (
              dataItem.engine == "media" &&
              dataItem.validAssetLength < 5 &&
              dataItem.status == 1
            ) {
              this.mediaError = true;
            } else if (
              dataItem.engine == "media" &&
              dataItem.validAssetLength > 5 &&
              dataItem.status == 1
            ) {
              this.mediaError = false;
            }

            //   this.mediaError = dataItem.validAssetLength > 5 ? true : false
            //   this.mediaError = this.question.type == 16 ? false : this.mediaError
            //   dataItem.assets['valid'] =true
            return dataItem;
          });
          if (type == "question") {
            this.whiteQuestionContent = [];
            this.whiteQuestionContent = this.content.filter(
              (q) => q.status == 1
            );
            this.blackQuestionContent = this.content.filter(
              (q) => q.status == -1
            );
          } else if (type == "tip") {
            this.whiteTipContent = this.content.filter((t) => t.status == 1);
            this.blackTipContent = this.content.filter((t) => t.status == -1);
          } else {
            this.whiteSolutionContent = this.content.filter(
              (s) => s.status == 1
            );
            this.blackSolutionContent = this.content.filter(
              (s) => s.status == -1
            );
          }
        })
        .catch(() => {
          this.retryQueue.push({
            action: "contentLoad",
            params: { typeId, type },
          });
        });
    },
    fetchAnswers() {
      if (this.question.type == 16) {
        if (!this.currentCorrectAnswer.length) {
          return [];
        }
        this.whiteAnswerContent = [
          {
            type: 16,
            data: this.currentCorrectAnswer.toString(),
            status: 1,
            engine: "text",
          },
        ];
        return;
      }
      apiClient
        .get(
          "/v1/office/assessment/answer/fetch/question/" + this.question.qlid
        )
        .then((res) => {
          this.answers = res.data.data;
          this.whiteAnswerContent = this.answers.filter((a) => a.status == 1);
          this.whiteAnswerContent = this.whiteAnswerContent
            .map((a) => {
              a.rank = this.currentCorrectAnswer.indexOf(a.alid) + 1;
              return a;
            })
            .reverse();

          this.blackAnswerContent = this.answers.filter((a) => a.status == -1);
        })
        .catch(() => {
          this.retryQueue.push({ action: "fetchAnswers" });
        });
    },
    selectAnswer(alid) {
      const pos = this.newCorrectAnswer.indexOf(alid);
      if (this.question.type == 1 || this.question.type == 2) {
        this.newCorrectAnswer = [alid];
      } else if (this.question.type == 4 || this.question.type == 8) {
        if (pos >= 0) {
          this.newCorrectAnswer.splice(pos, 1);
        } else {
          this.newCorrectAnswer.push(alid);
        }
        if (this.question.type == 8) {
          EventBus.$emit("answerRank", this.newCorrectAnswer);
        }
      }
    },
    retryAction() {
      for (let i of this.retryQueue) {
        switch (i) {
          case "questionPull":
            this.questionPull(i.params.ctx);
            this.retryQueue.splice(this.retryQueue.indexOf("questionPull"), 1);
            break;
          case "contentLoad":
            this.contentLoad(i.params.typeId, i.params.type);
            this.retryQueue.splice(this.retryQueue.indexOf("contentLoad"), 1);
            break;
          case "fetchAnswers":
            this.fetchAnswers();
            this.retryQueue.splice(this.retryQueue.indexOf("fetchAnswers"), 1);
            break;
          default:
            break;
        }
      }
    },
    resetAnswer() {
      const answers = [].concat(this.whiteAnswerContent);
      jQ(".correctAnswer").removeClass("correctAnswer");
      EventBus.$emit("deselectAnswer", [...this.newCorrectAnswer]);

      setTimeout(() => {
        for (let a of this.currentCorrectAnswer) {
          jQ("#" + a).trigger("click");
        }
        this.whiteAnswerContent = answers;
        this.newCorrectAnswer = [...this.currentCorrectAnswer];
      }, 300);
    },
    contentOperations(data) {
      if (data.action == "add") {
        switch (data.contentType) {
          case 1:
            this.contentLoad(
              data.contentId,
              "question",
              data.loaderSelector,
              data.buttonSelector
            );
            break;
          case 4:
            this.contentLoad(
              data.contentId,
              "tip",
              data.loaderSelector,
              data.buttonSelector
            );
            break;
          case 8:
            this.contentLoad(
              data.contentId,
              "solution",
              data.loaderSelector,
              data.buttonSelector
            );
            break;
          default:
            break;
        }
      } else if (data.action == "delete") {
        this.contentLoad(
          data.contentId,
          data.contentType,
          data.loaderSelector,
          data.buttonSelector
        );
      }
    },
    buildQuestion() {
      this.$store.commit("loader", true);
      apiClient
        .post("/v1/office/assessment/question/submit", {
          qlid: this.question.qlid,
        })
        .then((res) => {
          this.$store.commit("loader", false);
          this.$store.commit("ctoast", {
            type: "success",
            message: res.data.message,
            timeout: 10000,
          });
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.resModal.open({
            type: "error",
            message: err.response.data.message,
          });
        });
    },
    submitCorrectAnswer() {
      this.$store.commit("loader", true);
      apiClient
        .patch("/v1/office/assessment/question/correct", {
          qlid: this.question.qlid,
          items: this.newCorrectAnswer,
        })
        .then(() => {
          this.$store.commit("loader", false);
          this.currentCorrectAnswer = [...this.newCorrectAnswer];
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 7000,
          });
          this.$refs.resModal.open({
            type: "error",
            message: err.response.data.message,
          });
        });
    },
  },
  validations: {
    openAnswer: {
      required,
    },
  },
};
</script>
<style>
.minh-180 {
  min-height: 180px;
}
.link{
  color: #d94f00; cursor: pointer
}
</style>