<template>
  <aside class="mailbox-sidebar-container" v-if="question">
    <div class="atbd-mail-sidebar show mb-30">
      <div class="card normHeight">
        <div class="card-body">
          <div class="d-flex align-content-center content-center px-15">
            <button
              disabled
              class="btn-compose btn btn-md btn-primary btn-shadow-primary"
              data-trigger="compose"
            >
              <i class="las la-psen"></i> Question Presets
            </button>
          </div>
          <div class="presets">
            <span class="hh"> Class </span>
            <span class="hs">
              <span class="atbd-tag tag-primary tag-transparented">{{
                className
              }}</span>
            </span>
            <span class="hh"> Subject </span>
            <span class="hs">
              <span class="atbd-tag tag-primary tag-transparented">{{
                subjectName
              }}</span>
            </span>
            <span class="hh"> Topic </span>
            <span class="hs">
              <span class="atbd-tag tag-primary tag-transparented">{{ topicName }}</span>
            </span>
            <span class="hh mt-2 pb-0"> Description </span>
            <small class="pl-3 pr-0 mt-0">{{ question.description }}</small>
            <span class="hh mt-2"> Question Type </span>
            <span class="hs">
              <span class="atbd-tag tag-primary tag-transparented">{{
                questionTypeMap[question.type]
              }}</span>
            </span>

            <span class="hh"> Label </span>
            <span class="hs">
              <span class="atbd-tag tag-primary tag-transparented">{{
                question.label
              }}</span>
            </span>

            <span class="hh"> Scheme </span>
            <span class="hs">
              <span
                class="atbd-tag tag-primary tag-transparented"
                v-for="(scheme, k) in question.category_scheme"
                :key="k"
              >
                {{ scheme }}<sup>st</sup> Term</span
              >
            </span>

            <span class="hh" style="margin-bottom:35px"> Difficulty </span>
                  <span class="hs">
                    <div class="slider-wrapper">
                      <div class="atbd-slider">
                        <div
                          class="
                            slider-basic
                            ui-slider
                            ui-corner-all
                            ui-slider-horizontal
                            ui-widget
                            ui-widget-content
                            ui-slider-disabled
                            ui-state-disabled
                          "
                        >
                          <div
                            class="
                              ui-slider-range
                              ui-corner-all
                              ui-widget-header
                              ui-slider-range-min
                            "
                            :style="{width: difficulty}"
                          ></div>
                          <span
                            tabindex="0"
                            class="
                              ui-slider-handle ui-corner-all ui-state-default
                            "
                            :style="{left: difficulty}"
                            ><span class="tooltip-text" style="display:block">{{parseInt(difficulty)/10}}</span></span>
                        </div>
                      </div>
                    </div>
                  </span>

            <span class="hh"> Duration </span>
            <span class="hs">
              <span class="atbd-tag tag-danger tag-transparented">{{
                question.duration
              }}</span>
            </span>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
      <!-- ends: .card -->
    </div>
    <!-- ends: .atbd-mail-sidebar -->
  </aside>
</template>
<script>
import { questionTypeMap } from "@/utilities/constant";
import { mapState } from 'vuex';

export default {
  data() {
    return {
      questionTypeMap,
    };
  },

  computed: {
      ...mapState(["classes","subjects"]),
    // topicName() {
    //   // return (
    //   //   this.topics.find((i) => i.tolid == this.question.tolid)?.name || "none"
    //   // );
      
    //   return ''
    // },
    subjectName() {
      return (
        this.subjects.find((i) => i.sulid == this.question.sulid)?.name || "none" 
      );
    },
    className() {
      const classes = this.classes.filter(
        (c) => c.cllid == this.question.cllid
      );

      return classes.length ? classes[0].name : "";
    },
    difficulty(){
      return (this.question.difficulty/10)*100 + '%'
    },
  },
  props: ["question", 'topicName'],
};
</script>